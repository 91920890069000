import React from "react";
import logo from "./logo.png";
import "./App.css";
import DarkMode from "./DarkMode";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>
          Antonio Vbn
        </h1>
        <p>
        Web developer and gamer
        </p>
        <p>
        Discord username: Antonio_(vbn)#9085
        </p>
        &nbsp;
        <DarkMode />
      </header>
    </div>
  );
}

export default App;